@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .button {
    @apply w-40 h-24 min-w-96;
    @apply m-4;
    @apply border-0;
    @apply font-bold text-xl no-underline;
    @apply flex flex-col items-center justify-center;
  }

  .upload-button {
    @apply w-40 h-24 min-w-96;
    @apply m-4;
    @apply border-0;
    @apply font-bold text-xl no-underline;
    @apply flex flex-col items-center justify-center;
  }

  .dropzone {
    @apply w-80	h-80;
    @apply flex flex-col items-center justify-center;
    @apply font-bold text-base text-center;
    @apply absolute;
    @apply bg-ivory;
  }

  .dropzone-image {
    @apply w-80 h-80;	
  }

  .spin {
    @apply animate-spin;
  }

  .upload-section {
    @apply relative;
    @apply flex flex-col justify-center items-center;
    @apply p-4;
    @apply top-44;
  }

  .thumb-container {
    @apply flex flex-row flex-wrap;
    @apply mt-4;
  }

  .thumb {
    @apply inline-flex;
    @apply w-full h-full;
    @apply mb-4 mr-4 p-2;
    @apply box-border border rounded;
  }

  .thumb-inner {
    @apply flex min-w-0 overflow-hidden;
  }

  .upload-img {
    @apply block w-auto h-full;
  }
}

/* .small-button {
  @apply w-40 h-12 min-w-96;
  @apply m-4;
  @apply border-0;
  @apply font-bold text-xl no-underline;
  @apply flex flex-col items-center justify-center;    
} */